import { DATE_FORMAT } from 'constants/dateFormats'
import getStatus from 'containers/MainContent/Orcatec/Proposals/helpers/getTabStatus'
import moment from 'moment-timezone'
import { Job } from 'types/Jobs'
import { ContactI } from '../modals/NotificationModal/types'
import { Company } from 'features/Settings/Subscription/types'
import { User } from 'types/User'
import { ProjectItem } from 'features/Project/types'

const formatTime = time => {
  if (typeof time === 'string') {
    return moment(time, 'HH:mm').format('hh:mm A')
  } else if (moment.isMoment(time)) {
    return time.format('hh:mm A')
  } else {
    return null
  }
}

export const parseEmailMessage = (
  message: string,
  type: 'sms' | 'email',
  source: {
    company: Company
    authUser: User
    event: Job & { tech: ContactI | undefined }
    scopeOfWork: ProjectItem[]
  },
) => {
  const { event, company, authUser, scopeOfWork } = source

  let newMessage = message
  const project = event.proposal_info
  const currentTab = project?.proposal_tabs?.[0]

  const companyPhone = company.company_phone
  const companyName = company.company_name
  const companyEmail = company.company_email
  const userEmail = authUser.email
  const userPhone = authUser.phone

  const { time_start, time_end, matrix_time_start, matrix_time_end } =
    event || {}

  const arrivalTimeFormat = (time_start: string, time_end: string) => {
    if (formatTime(time_start) === formatTime(time_end)) {
      return time_start ? moment(time_start, 'HH:mm').format('hh:mm A') : '-'
    }
    return `${
      time_start ? moment(time_start, 'HH:mm').format('hh:mm A') : '-'
    } - ${time_end ? moment(time_end, 'HH:mm').format('hh:mm A') : '-'}`
  }

  const promisedArrivalTime = arrivalTimeFormat(time_start, time_end)
  const dispatchTime = arrivalTimeFormat(matrix_time_start, matrix_time_end)

  const {
    name: techName,
    phones: techPhone,
    emails: techEmail,
    type: isClient,
  } = event?.tech ?? {}

  const date =
    typeof event?.date === 'string'
      ? moment(event?.date).format(DATE_FORMAT)
      : Array.isArray(event?.date)
      ? event?.date.map(date => moment(date).format(DATE_FORMAT)).join(`,<br/>`)
      : null

  const link = 'link'

  const isSMS = type === 'sms'
  const obj: { [key: string]: string } = {
    // Proposal
    '[link to proposal]': isSMS
      ? link
      : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link}>link</a>`,
    '[link_to_proposal]': isSMS
      ? link
      : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link}>link</a>`,
    // Project
    '[link to project]': isSMS
      ? link
      : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link}>link</a>`,
    '[link_to_project]': isSMS
      ? link
      : `<a style='color: #0000EE; text-decoration: none' rel='noreferrer' target='_blank' href=${link}>link</a>`,
    '[tech_name]': !isClient ? techName || '' : authUser.full_name,
    '[recipient_name]': techName || '',
    '[tech_email]': techEmail || authUser.email || '',
    '[tech_phone_number]': techPhone || authUser.phone || '',
    '[company_name]': companyName, //Company name
    '[user_name]': '',
    '[user_email]': userEmail || '', //User email. We are using the current user from matrix on that assigned appointment. When appt in queue or misc we are using authenticated user
    '[user_phone]': userPhone || '',
    '[company_email]': companyEmail, //Company email
    '[company_phone_number]': companyPhone, //Comany phone number
    '[promised_arrival_time]': promisedArrivalTime,
    '[dispatch_time]': dispatchTime,
    '[date]': date,
    '[appointment_date]': project?.created_at
      ? moment(project?.created_at).format('MM/DD/YYYY')
      : date,
    '[project_id]': project?.code || '',
    // Proposal
    '[ProposalStatus (Estimate, Invoice, etc.)]':
      (project && project && getStatus(project, currentTab)) || '',
    '[ProposalStatus(Estimate, Invoice, etc.)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProposalHeader (Estimate, Invoice, etc.)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProposalHeader(Estimate || Invoice || Receipt)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProposalHeader (Estimate || Invoice || Receipt)]':
      (project && getStatus(project, currentTab)) || '',
    // Project
    '[ProjectStatus (Estimate, Invoice, etc.)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProjectStatus(Estimate, Invoice, etc.)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProjectHeader (Estimate, Invoice, etc.)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProjectHeader(Estimate || Invoice || Receipt)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProjectHeader (Estimate || Invoice || Receipt)]':
      (project && getStatus(project, currentTab)) || '',
    '[ProjectHeader (Estimate, etc.)]':
      (project && getStatus(project, currentTab)) || '',
    '[projectheader (estimate, invoice, etc.)]':
      (project && getStatus(project)) || '',
    '[proposal status]': (project && getStatus(project, currentTab)) || '',
    '[project status]': (project && getStatus(project, currentTab)) || '',
    '[Project status]': (project && getStatus(project, currentTab)) || '',

    //Work order
    '[work_order_id]': project?.code || '',
    '[work_order_name]': project?.name || '',
    '[notes_for_worker]': project?.proposal_tabs?.[0]?.notes_for_installers
      ? project?.proposal_tabs?.[0]?.notes_for_installers.replaceAll(
          '\n',
          '<br/>',
        )
      : '',
    '[work_order_primary_property]': project?.properties?.length
      ? `${project?.properties?.[0].address}, ${project?.properties?.[0].city}, ${project?.properties?.[0].state}, ${project?.properties?.[0].postcode}`
      : '',
    '[visit_date(s)]': event?.date?.length
      ? `<br/>${event?.date
          .map(date => `${moment(date).format(DATE_FORMAT)} ${dispatchTime}`)
          .join(`,<br/>`)}`
      : '',
    '[work_order_scope_of_work]': scopeOfWork?.length
      ? scopeOfWork
          .map(item => `${item.name} - ${item.qty}${item.unit || 'qty'}`)
          .join(',</br>')
      : '',
  }

  Object.keys(obj).forEach(key => {
    if (message?.includes(key)) {
      newMessage = newMessage.replaceAll(key, obj[key])
    }
  })

  return newMessage
}
